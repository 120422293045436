.App {
  text-align: center;
}

body {
  background-color: #F0F0F0;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* swal modification */
.swal-title {
  color: #333333;
  font-weight: 400; 
  font-size: 24px;
}

.swal-text {
  color: #333333;
  /* font-weight: 600; */
}

.swal-footer {
  display: flex;
  justify-content: center;
}

.swal-icon--success__ring {
  width: 80px;
  height: 80px;
  border: 4px solid hsla(98, 55%, 69%, .2);
  border-radius: 50%;
  box-sizing: content-box;
  position: absolute;
  left: -4px;
  top: -4px;
  z-index: 2;
}

.swal-icon--success:after,
.swal-icon--success:before {
  content: "";
  border-radius: 50%;
  position: absolute;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  display: none;
}

.swal-icon--success__hide-corners {
  width: 5px;
  height: 90px;
  position: absolute;
  left: 28px;
  top: 8px;
  z-index: 1;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  display: none;
}
 
/* inputbox  */
.input {
  border:1px solid #B0AEFF;
  background-color:#ffffff;
  height:40px;
  color:#444444;
  font-size:14px;
  line-height: 17px;
  width:100% ;
  border-radius:8px;
  padding:0.1;
  margin-top:8px;
  padding-left:10px;
}

/* custom popup css */

.custom-modal {
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 70px 70px;
  gap: 32px;
  position: absolute; */
  width: 570px;
  height: 'auto';
  /* Padding: 0px 50px; */
 
  background: #F5F4F4;
  box-shadow: 0px 4px 40px #737373;
  border-radius: 40px;
}

.custom-modal-user {
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 70px 70px;
  gap: 32px;
  position: absolute; */
  width: 550px;
  height: 'auto';
  /* Padding: 0px 50px; */
  background: #F5F4F4;
  box-shadow: 0px 4px 40px #737373;
  border-radius: 40px;
}

.custom-modal {
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 70px 70px;
  gap: 32px;
  position: absolute; */
  width: 570px;
  height: 'auto';
  /* Padding: 0px 50px; */
  background: #F5F4F4;
  box-shadow: 0px 4px 40px #737373;
  border-radius: 40px;
}
.popup-green {
  width: 224px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin: 20px;
  gap: 10px;
  height: 40px;
  background: #FFFFFF;
  border: 2px solid #3CD307;
  border-radius: 40px;
  flex: none;
  order: 0;
  flex-grow: 1;
  letter-spacing: 0.20em;
  font-weight: 600;
  color: #3CD307;
  font-size: 16px;
}

.popup-red {
  width: 224px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin: 20px;
  gap: 10px;
  height: 40px;
  background: #FFFFFF;
  border: 2px solid #E70707;
  border-radius: 40px;
  flex: none;
  order: 0;
  flex-grow: 1;
  letter-spacing: 0.20em;
  font-weight: 600;
  color: #E70707;
  font-size: 16px;
}

.popup-blue {
  width: 224px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin: 20px;
  gap: 10px;
  height: 40px;
  background: #FFFFFF;
  border: 2px solid #7673F6;
  border-radius: 40px;
  flex: none;
  order: 0;
  flex-grow: 1;
  letter-spacing: 0.20em;
  font-weight: 600;
  color: #7673F6;
  font-size: 16px;
}

.popup-blueOne{
  width: 224px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin: 20px;
  gap: 10px;
  height: 40px;
  background: #FFFFFF;
  border: 2px solid #7673F6;
  border-radius: 40px;
  flex: none;
  order: 0;
  flex-grow: 1;
  letter-spacing: 0.20em;
  font-weight: 600;
  color: #7673F6;
  font-size: 16px;
}

.css-46bh2p-MuiCardContent-root:last-child {
  padding-bottom: "14px"
}


.popup-gray {
  width: 224px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin: 20px;
  gap: 10px;
  height: 40px;
  background: #FFFFFF;
  border: 2px solid #f55516;
  border-radius: 40px;
  flex: none;
  order: 0;
  flex-grow: 1;
  letter-spacing: 0.20em;
  font-weight: 600;
  color: #f55516;
  font-size: 16px;
}

.popup-gray1 {
  width: 230px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px;
  margin: 20px;
  gap: 10px;
  height: 50px;
  background: #FFFFFF;
  border: 2px solid #f55516;
  border-radius: 40px;
  flex: none;
  order: 0;
  flex-grow: 1;
  letter-spacing: 0.20em;
  font-weight: 600;
  color: #f55516;
  font-size: 16px;
}

/* heading and sub heading css */

.heading {
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  letter-spacing: 0.04em;
  color: #444444;
}

.sub-heading {
  font-weight: 600;
  font-size: 16px;
  line-height: 34px;
  letter-spacing: 0.04em;
  color: #444444;
}

.label{
  font-weight: 600;
  font-size: 14px;
  line-height: 34px;
  letter-spacing: 0.04em;
  color: #444444;
}

::-webkit-scrollbar {

   width: 5px;
  
  height:5px;
  
  
  }
  
  
  
  
  /* Track */
  
  ::-webkit-scrollbar-track {
  
  /* box-shadow: inset 0 0 5px grey;  */
  
  border-radius: 10px;
  
  }
  

  
  /* Handle */
  
  ::-webkit-scrollbar-thumb {
   background: #7673F6;
   border-radius: 10px;
  
  }

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}